<template>
    <b-container class="mt-5">
        <component
            v-if="isWaiting === false"
            v-bind:is="currentTask.component"
            :task-type="currentTask.type"
            :task-version="currentTask.version"
            :task-json="currentTask.json"
            :study-code="currentTask.studyCode"
            :skip-intro="true"
            :must-continue="true"
            :study="this"
            @taskCompleted="onTaskComplete"
            @abandonStudy="onAbandonStudy"
        />

        <b-card no-body
                v-show="isWaiting === true || hasError === true">
            <b-card-body ref="htmlContent">
                <b-skeleton animation="wave" width="85%"></b-skeleton>
                <b-skeleton animation="wave" width="55%"></b-skeleton>
                <b-skeleton animation="wave" width="70%"></b-skeleton>
            </b-card-body>
        </b-card>

    </b-container>
</template>

<script>

import LexTaleTask from "@/components/LexTaleTask";
import NarrativeTask from "@/components/NarrativeTask";
import GenericTimelineTask from "@/components/GenericTimelineTask";
import PlacementTask from "@/components/PlacementTask";
import axios from "axios";

export default {
    name      : "StudyLoader",
    components: {
        LexTaleTask,
        NarrativeTask,
        GenericTimelineTask,
        PlacementTask,
    },
    props     : {},
    data()
    {
        return {
            currentTask : {component: null, version: null, json: null, ready: false, studyCode: null},
            studyCode   : '',
            studyJson   : null,
            error       : null,
            timeline    : [],
            currentIndex: 0,
            participantData: {}
        }
    },
    beforeMount()
    {
        this.studyCode = this.$route.params.studyCode;
        this.loadStudyData();
    },
    computed: {
        isWaiting: function () {
            return this.studyJson === null || this.currentTask === null || this.currentTask.json === null || this.currentTask.ready === false;
        },
        hasError : function () {
            return this.studyJson === null && this.error !== null;
        }
    },
    methods : {
        loadStudyData : function () {
            axios.get('/json/studies/' + this.studyCode + ".json")
                .then(response => {
                    this.parseStudyData(response.data);
                })
                .catch(error => this.showError(error, 'study'));
        },
        parseStudyData: function (data) {
            let uniqueID = 0;
            this.studyJson = data;

            this.studyJson.timeline.forEach(task => {
                task = Object.assign({
                    'id'       : uniqueID++,
                    'index'    : this.timeline.length,
                    'ready'    : false,
                    'json'     : null,
                    'component': task.data.type,
                    'studyCode': this.studyCode
                }, task.data);
                this.timeline.push(task);
                this.loadTask(task);
                console.log(uniqueID + ". " + task.type + ": " + task.version);
            })

            this.initTask(this.currentIndex);
        },
        loadTask      : function (task) {
            axios.get('/json/tasks/' + task.type + "-" + task.version + ".json")
                .then(response => {
                    this.timeline[task.index].json = response.data;
                    this.timeline[task.index].ready = true;
                    task = this.timeline[task.index];
                    if (task.index === this.currentIndex) {
                        this.initTask(task);
                    }
                })
                .catch(error => this.showError(error, 'task'));
        },
        initTask      : function (task) {
            if (task && task.ready === true) {
                console.log("Init task " + task.index);
                this.currentTask = task;
            }
        },
        onTaskComplete : function(taskComponent){
            console.log(taskComponent + " completed");
            this.currentIndex++;
            this.currentTask = null;
            this.$nextTick(() => {
                this.initTask(this.timeline[this.currentIndex]);
            })
        },
        onAbandonStudy: function(){
            console.log('redirect')
            this.$router.push('/etudes-en-cours');
        },
        showError     : function (error, type)
        {
            this.error = error;
            this.$refs["htmlContent"].innerHTML = "Oops, il semblerait " + (type === 'study' ? "que cette étude" : "qu'une tâche de cette étude") + " ne peut être chargée pour le moment!<br/>" + error;
        },
        getNextTask: function(){
            return this.timeline[this.currentIndex+1];
        }

    }
}
</script>

<style scoped></style>
