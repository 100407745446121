<template>
    <b-container class="mt-5">
        <b-overlay :show="this.showOverlay" rounded="sm">
            <component
                :is="this.currentComponentData.component"
                v-bind="this.currentComponentData.props"
                :key="this.currentIndex"
                @triggerNext="goToNext()"
                @saveAudioPath="saveFormData"
                @saveFormInformation="saveFormData"
                @skipToSection="skipToSection"
                :task="this"
                v-show="this.currentComponentData.component !== null"/>
            <template #overlay>
                <div class="text-center">
                    <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
                    <p id="cancel-label">{{ overlayMessage }}</p>
                </div>
            </template>
        </b-overlay>
    </b-container>
</template>

<script>
import ExternalHtmlContent from '@/components/TasksContent/ExternalHtmlContent';
import ExternalHtmlConsentForm from '@/components/TasksContent/ExternalHtmlConsentForm';
import JsonForm from '@/components/TasksContent/JsonForm';
import ExternalVideoPlayer from '@/components/TasksContent/ExternalVideoPlayer';
import SimpleTextSlide from '@/components/TasksContent/SimpleTextSlide';
import AudioNarrativeRecorder from '@/components/TasksContent/AudioNarrativeRecorder';
import PauseOrRetryLater from '@/components/TasksContent/PauseOrRetryLater';
import SurveyMultiChoice from '@/components/TasksContent/SurveyMultiChoice';
import TaskResults from '@/components/TasksContent/TaskResults';
import SaveableTask from "@/components/SaveableTask.js"

export default {
    extends   : SaveableTask,
    name      : "GenericTimelineTask",
    components: {
        ExternalHtmlContent,
        ExternalHtmlConsentForm,
        JsonForm,
        ExternalVideoPlayer,
        SimpleTextSlide,
        AudioNarrativeRecorder,
        PauseOrRetryLater,
        SurveyMultiChoice,
        TaskResults
    },
    props:{
        displayResults: {type: Boolean, default: true},
        mustContinue  : {type: Boolean, default: false}
    },
    data()
    {
        return {}
    },
    methods: {
        parseTaskData : function (data) {
            let uniqueID = 0;
            //let order = 1;

            // Continue with the task itself
            for (let i in data.timeline) {
                // task items
                this.taskItems++;
                console.log(data.timeline[i]);
                this.timeline.push({
                    id       : data.timeline[i].id ?? uniqueID++,
                    component: data.timeline[i].type,
                    props    : Object.assign({}, data.common, data.timeline[i].props)
                });
            }
            return true;
        },
        skipToSection : function (section) {
            console.log(section);
            let skipTo = this.timeline.findIndex(el => {
                return el.id === section;
            });
            if (skipTo > 0) {
                this.currentIndex = skipTo;
                this.initComponent(this.timeline[this.currentIndex]);
            } else {
                console.log("Cannot find the section to skip to.")
            }

        },
        prepareResults: function ()
        {
            this.exportableResults = {
                participantData: this.participantData,
                timelineData   : this.timelineData,
            };
        }

    }
}
</script>
