<template>
    <div>
        <b-container class="mt-3">
            <h1 class="mb-5">Tâches disponibles (ou en cours de développement)</h1>
            <b-row cols="2">
                <b-col v-for="task in tasks" :key="task.id" sm="6" class="mb-3">
                    <b-card>
                        <b-card-title>
                            {{ task.name }}
                            <b-icon :icon="task.icon" v-if="task.icon !== null"/>
                        </b-card-title>
                        <b-card-text>
                            {{ task.description }}
                        </b-card-text>
                        <b-button :to="task.link" :variant="task.link === '#' ? 'secondary' : 'primary'">
                            {{ task.buttonText ? task.buttonText : 'Essayez maintenant!' }}
                        </b-button>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
    name: "LanguageTaskList",
    data()
    {
        return {
            tasks: [
                {
                    id         : "lextale-en",
                    name       : "LexTALE (EN)",
                    description: "Le test de vocabulaire LexTALE en anglais",
                    link       : "/tasks/lex-tale-task/en-best",
                    icon       : "spellcheck"
                },
                {
                    id         : "lextale-fr",
                    name       : "LexTALE (FR)",
                    description: "Le test de vocabulaire LexTALE en français",
                    link       : "/tasks/lex-tale-task/fr-brysbaert",
                    icon       : "spellcheck"
                },
                {
                    id         : "lextale-es",
                    name       : "LexTALE (ES)",
                    description: "Le test de vocabulaire LexTALE en espagnol",
                    link       : "/tasks/lex-tale-task/es-best",
                    icon       : "spellcheck"
                },
                {
                    id         : "lextale-eu",
                    name       : "LexTALE (EU)",
                    description: "Le test de vocabulaire LexTALE en basque",
                    link       : "/tasks/lex-tale-task/eu-best",
                    icon       : "spellcheck"
                },
                {
                    id         : "narrative-tam-video-en",
                    name       : "Narration (EN)",
                    description: "Une narration (orale et écrite) à propos d'une vidéo.",
                    link       : "/tasks/narrative-task/en-tam-video",
                    icon       : "film"
                },
                {
                    id         : "narrative-tam-video-fr",
                    name       : "Narration (FR)",
                    description: "Une narration (orale et écrite) à propos d'une vidéo.",
                    link       : "/tasks/narrative-task/fr-tam-video",
                    icon       : "film"
                },
                {
                    id         : "narrative-tam-video-es",
                    name       : "Narration (ES)",
                    description: "Une narration (orale et écrite) à propos d'une vidéo.",
                    link       : "/tasks/narrative-task/es-tam-video",
                    icon       : "film"
                },
                {
                    id         : "placement-dele-es",
                    name       : "Test de positionnement - DELE (ES)",
                    description: "Un test de positionnement inspiré du DELE.",
                    link       : "tasks/placement-task/es-dele",
                    icon       : "signpost-split"
                },
                {
                    id         : "repeat-as-much-as-you-can-fr",
                    name       : "Repeat as much as you can (FR)",
                    description: "Une tâche de répétition de phrases en français.",
                    link       : "#",
                    buttonText : "À venir",
                    icon       : "mic"
                },
                {
                    id         : "repeat-as-much-as-you-can-es",
                    name       : "Repeat as much as you can (ES)",
                    description: "Une tâche de répétition de phrases en espagnol.",
                    link       : "#",
                    buttonText : "À venir",
                    icon       : "mic"
                },
                {
                    id         : "repeat-as-much-as-you-can-en",
                    name       : "Repeat as much as you can (EN)",
                    description: "Une tâche de répétition de phrases en anglais.",
                    link       : "#",
                    buttonText : "À venir",
                    icon       : "mic"
                },
                {
                    id         : "repeat-as-much-as-you-can-eu",
                    name       : "Repeat as much as you can (EU)",
                    description: "Une tâche de répétition de phrases en basque.",
                    link       : "#",
                    buttonText : "À venir",
                    icon       : "mic"
                },
            ]
        }
    }

}
</script>

<style scoped>

</style>
