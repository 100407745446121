<template>
    <b-card v-show="this.task.exportableResults !== null" no-body>
        <b-card-body v-if="this.task.displayResults">
            <b-card-text>{{ this.task.commonText.taskCompleteWithResults }}</b-card-text>
            <b-card-text>{{this.task.commonText.totalScore }} {{ this.task.formattedScore }}</b-card-text>
            <b-card-text v-if="this.task.mustContinue">{{ this.task.commonText.clickToContinue }}</b-card-text>
        </b-card-body>
        <b-card-body v-else>
            <b-card-text>{{ this.task.commonText.taskComplete }}</b-card-text>
            <b-card-text v-if="this.task.mustContinue">{{ this.task.commonText.clickToContinue }}</b-card-text>
        </b-card-body>
        <b-card-footer v-if="this.task.mustContinue">
            <b-button @click="triggerNext()" v-show="nextLabel !== null" variant="primary">{{ nextLabel }}</b-button>
        </b-card-footer>

    </b-card>
</template>

<script>
import SaveableTask from "../SaveableTask";

export default {
    name: "TaskResults",
    props:{
        task: SaveableTask,
        nextLabel: String,
    },
    data (){
        return {
        }
    },
    methods:{
        triggerNext(){
            this.$emit("triggerNext", true)
        }
    }
}
</script>

<style scoped>

</style>
