<template>
    <b-card no-body>
        <b-card-body>
            <div ref="htmlContent">
                <b-skeleton animation="wave" width="85%"></b-skeleton>
                <b-skeleton animation="wave" width="55%"></b-skeleton>
                <b-skeleton animation="wave" width="70%"></b-skeleton>
            </div>
            {{ nextTaskFullUrl }}
            <countdown @complete="onCountdownComplete" v-show="showCountdown" :total-seconds="countdownDuration"/>
            <p v-show="countdownComplete">{{ textCountdownComplete }}</p>
        </b-card-body>
        <b-card-footer>
            <b-button @click="triggerNext()" v-show="nextLabel !== null" variant="primary"
                      :disabled="countdownComplete === false && waitCountdownBeforeContinue">{{ nextLabel }}
            </b-button>
        </b-card-footer>
    </b-card>
</template>

<script>
import axios from 'axios'

export default {
    name : "PauseOrRetryLater",
    props: {
        fileName                   : String,
        nextLabel                  : String,
        task                       : Object,
        nextTaskUri                : String,
        countdownDuration          : Number,
        showCountdown              : Boolean,
        waitCountdownBeforeContinue: Boolean,
        textCountdownComplete      : String
    },
    data()
    {
        return {
            participantData  : {code: null},
            countdownComplete: false
        };
    },
    mounted()
    {
        axios.get('/html/' + this.fileName).then(response => this.$refs["htmlContent"].innerHTML = response.data)
        this.checkParticipantCode();
    },
    computed: {
        nextTaskFullUrl()
        {
            if (this.participantData.code === null) {
                return '...'
            }
            return location.protocol + "//" + window.location.host + window.location.port + '/#' + this.nextTaskUri + '/' + this.participantData.code;
        }
    },
    methods : {
        triggerNext         : function () {
            this.$emit("triggerNext");
        },
        checkParticipantCode: function () {
            this.participantData = this.task.participantData ?? {code: null}
            if (!this.participantData.code) {
                axios.post(process.env.VUE_APP_API_SERVER + "/participants", {})
                    .then(response => {
                        this.$set(this.participantData, "code", response.data.code);
                    })
            }
        },
        onCountdownComplete : function () {
            this.countdownComplete = true;
        },
        continueLater       : function () {
            axios.post(process.env.VUE_APP_API_SERVER + "/test/continue-later",
                    {
                        study           : this.studyCode ?? '',
                        task_type       : this.taskType,
                        task_version    : this.taskVersion,
                        participant_code: this.participantData.participant_code ?? 'ANONYMOUS',
                        data            : this.exportableResults
                    })
                .then(response => {
                    this.savingStatus = 'saved';
                    console.log(response)
                });
        }
    }
};
</script>
