<template>
    <b-card no-body>
        <b-card-body>
            <p v-html="this.nl2br(content)"></p>
        </b-card-body>
        <b-card-footer>
            <b-button v-if="canContinue" @click="triggerNext()" v-show="nextLabel !== null" variant="primary">
                {{ nextLabel }}
            </b-button>
        </b-card-footer>
    </b-card>
</template>

<script>
import SaveableTask from "@/components/SaveableTask";
import BasicContent from "./BasicContent";

export default {
    extends : BasicContent,
    name    : "SimpleTextSlide",
    props   : {
        content       : String,
        nextLabel     : String,
        isResultScreen: Boolean,
        task          : SaveableTask
    },
    computed: {
        canContinue: function () {
            let nextTask = this.task.study ? this.task.study.getNextTask() : null;
            let nextItem = this.task.getNextItem() ?? null;
            return nextItem != null || nextTask != null;
        }
    },
    methods : {
        triggerNext: function () {
            this.$emit('triggerNext');
        }
    }
}
</script>

<style scoped>

</style>
