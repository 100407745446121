import moment from "moment";
import axios from "axios";

export default {
    name : "SaveableTask",
    props: {
        taskType   : String,
        taskVersion: String,
        taskJson   : Object,
        studyCode  : {type: String, default: null},
        skipIntro  : {type: Boolean, default: false},
        study      : Object
    },
    data()
    {
        return {
            timeline            : [],
            currentIndex        : 0,
            currentTaskIndex    : 0,
            currentComponentData: {component: null, props: null},
            taskStartedAt       : null,
            // Data to save
            exportableResults : null,
            participantData   : {},
            participantLoading: null,
            timelineData      : {},
            commonText        : {},
            savingStatus      : null,
            isTaskParsed      : false,
            showOverlay       : false,
            overlayMessage    : '',
            debug             : false,
        }
    },
    created()
    {
        if (process.env.NODE_ENV === 'development') {
            window.addEventListener('keydown', (e) => {
                if (e.key == 'ArrowRight') {
                    this.goToNext()
                }
            })
        }
        axios.defaults.headers.common = {'Authorization': 'Bearer ' + process.env.VUE_APP_API_TOKEN}
    },
    mounted()
    {
        if (this.$route.params.participantCode) {
            this.participantData = {code: this.$route.params.participantCode}
            this.getParticipantInfos();
        } else {
            this.participantData = this.study ? this.study.participantData : {};
            this.participantLoading = false;
        }
        this.commonText = this.taskJson.common
        this.debug = this.taskJson.debug ?? false;
    },
    watch   : {
        participantLoading: function (newValue) {
            if (!newValue && !this.isTaskParsed) {
                this.prepareTask();
                this.showOverlay = false;
            } else if (newValue) {
                this.showOverlay = true;
                this.overlayMessage = this.commonText.participantLoadingMessage ?? "Chargement des informations du participant...";
            } else if (!newValue) {
                this.showOverlay = false;
            }
        },
        savingStatus      : function (newValue) {
            if (newValue === 'saving') {
                this.showOverlay = true;
                this.overlayMessage = this.commonText.savingMessage ?? "...";
            } else if (newValue === 'saved') {
                this.showOverlay = false;
                if (this.commonText.saveMessage) this.$bvToast.toast(this.commonText.saveMessage ?? '...', {variant: "success"});
            }
        }
    },
    computed: {
        isReady : function () {
            return this.isTaskParsed && !this.participantLoading;
        },
        isSaving: function () {
            return this.savingStatus === 'saving';
        },
        isTaskComplete: function(){
            return this.taskStartedAt !== null && (!this.timeline[this.currentIndex] || this.timeline[this.currentIndex].props.isResultScreen);
        }
    },
    methods : {
        prepareTask  : function () {
            if (this.beforeParsing) this.beforeParsing();
            this.isTaskParsed = this.parseTaskData(this.taskJson);
            if (this.afterParsing) this.afterParsing();
        },
        afterParsing : function () {
            if (this.taskJson.skipTo) {
                this.currentIndex = this.taskJson.skipTo;
            }
            this.initComponent(this.timeline[this.currentIndex]);
        },
        elapsedTime  : function () {
            if (!this.taskStartedAt) {
                return 0;
            }
            return moment().diff(this.taskStartedAt, 'seconds');
        },
        goToNext     : function (isTaskItem) {
            //Previous item
            if (isTaskItem ?? false) {
                this.currentTaskIndex++;
            }

            //Current item
            this.currentIndex++;
            //console.log("is next a result screen? ", this.timeline[this.currentIndex].props)
            if (this.isTaskComplete) {
                this.prepareResults();
                this.saveResults(!this.timeline[this.currentIndex]);
            }

            if (!this.timeline[this.currentIndex] && !this.isSaving && !this.participantLoading) {
                this.completeTask();
                return false;
            }

            this.initComponent(this.timeline[this.currentIndex])
        },
        initComponent: function (componentData) {
            if (componentData) {
                this.currentComponentData.component = componentData.component;
                this.currentComponentData.props = componentData.props;
                if ((this.currentComponentData.props.isTaskItem ?? false) &&
                        this.taskStartedAt === null) {
                    this.taskStartedAt = moment();
                    console.log("Test started at " + this.taskStartedAt.format())
                }
                window.scrollTo(0, 0);
            }
        },
        completeTask()
        {
            this.currentComponentData.component = null;
            this.$emit("taskCompleted", this);
        },
        saveFormData(data)
        {
            let currentItem = this.timeline[this.currentIndex];
            if (data.type === 'consent') {
                this.participantData.consent = data.content.filter(el => el.consent ?? false)[0].consent;
            } else if (data.type === 'participant-info') {
                this.participantData.study = this.studyCode;
                if (data.content.participant_code) {
                    this.participantData.code = data.content.participant_code;
                    this.getParticipantInfos(this.createNewParticipant);
                } else {
                    this.participantData.email_address = data.content.email;
                    this.createNewParticipant();
                }
            }
            this.timelineData[this.currentIndex + '-' + currentItem.id] = data.content;


        },
        prepareResults      : function ()
        {
            this.exportableResults = {
                elapsedTime    : this.elapsedTime(),
                participantData: this.participantData,
                timelineData   : this.timelineData
            };

        },
        saveResults         : function (autoCompleteWhenSaved) {
            //console.log("saving");
            if (['saving', 'saved'].includes(this.savingStatus) === false && this.participantLoading === false) {
                if (this.study) {
                    this.study.participantData = this.participantData;
                }
                this.savingStatus = 'saving';
                axios.post(process.env.VUE_APP_API_SERVER + "/test-results",
                                {
                                    study           : this.studyCode ?? this.participantData.study ?? null,
                                    task_type       : this.taskType,
                                    task_version    : this.taskVersion,
                                    participant_code: this.participantData.code ?? 'ANONYMOUS',
                                    data            : this.exportableResults
                                })
                        .then(response => {
                            response;
                            //console.log(response)
                            this.savingStatus = 'saved';
                            if (autoCompleteWhenSaved) {
                                this.completeTask();
                            }
                        });
            }
        },
        getParticipantInfos : function (onFailure) {
            this.participantLoading = true;
            axios.get(process.env.VUE_APP_API_SERVER + "/participants/" + this.participantData.code)
                    .then(response => {
                        this.participantData = response.data;
                        if (!response.data.id && onFailure) {
                            this.$bvToast.toast(this.commonText.participantCodeNotFound ?? 'Le code de participant fourni n\'existait pas', {variant: "danger"});
                            onFailure();
                        } else {
                            if (response.data.id) {
                                this.$bvToast.toast(this.commonText.participantCodeValidated ?? 'Votre code de participant est bien validé: ' + response.data.code, {variant: "success"});
                            }
                            this.participantLoading = false;
                        }
                    })
        },
        createNewParticipant: function () {
            this.participantLoading = true;
            axios.post(process.env.VUE_APP_API_SERVER + "/participants", this.participantData)
                    .then(response => {
                        this.participantData = response.data.participant;

                        if (response.data.warning) {
                            this.$bvModal.msgBoxConfirm(response.data.warning.message, {
                                okVariant    : 'warning',
                                okTitle      : response.data.warning.continueButton,
                                cancelVariant: 'danger',
                                cancelTitle  : response.data.warning.abandonButton
                            }).then((value) => {
                                if(!value) {
                                    this.$emit('abandonStudy');
                                }else{
                                    this.participantCreated();
                                }
                            });
                        }else{
                            this.participantCreated();
                        }
                    })
        },
        participantCreated(){
            console.log('Participant created!')
            this.$bvToast.toast(this.commonText.newParticipantCodeGenerated ?? 'Votre nouveau code de participant est: ' + this.participantData.code, {variant: "success"});
            this.participantLoading = false;
            if(this.isTaskComplete){
                this.saveResults(!this.timeline[this.currentIndex]);
            }
        },
        getNextItem         : function () {
            return this.timeline[this.currentIndex + 1] ?? null;
        }
    }
}
