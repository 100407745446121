<template>
    <div>
        <b-card no-body>
            <b-card-body>
                <b-card-title v-if="title !== ''">{{ title }}</b-card-title>
                <p v-if="intro !== ''" v-html="nl2br(intro)"/>
                <VueRecordAudio mode="press" @result="onResult" />
                <audio v-if="lastRecord.url !== null" ref="player" controls>
                    <source :src="lastRecord.url">
                </audio>
            </b-card-body>
            <b-card-footer>
                <b-button @click="triggerNext()" v-show="nextLabel !== null" variant="primary"
                          :disabled="fileRecorded === false">{{ nextLabel }}
                </b-button>
            </b-card-footer>
        </b-card>
    </div>
</template>

<script>
import axios from 'axios'

export default {

    name      : "AudioNarrativeRecorder",
    components: {},
    props     : {
        title     : {type: String, default: ''},
        intro     : {type: String, default: ''},
        attempts  : Number,
        times     : Number,
        nextLabel : String,
        isTaskItem: Boolean,
        task      : Object

    },
    data()
    {
        return {
            recorder    : null,
            fileRecorded: false,
            lastRecord  : {url: null}
        }
    },
    mounted()
    {

    },
    computed: {},
    methods : {

        checkOverwrite: function (event) {
            if (this.fileRecorded) {
                if (!prompt("Do you want to erase your current file?")) {
                    event.preventDefault();
                }
            }
        },
        onResult      : function (data) {
            let formData = new FormData();
            formData.append('data', data);
            formData.append("participant_code", this.task.participantData.code ?? null);
            formData.append("task_type", this.task.taskType ?? null);
            formData.append("task_version", this.task.taskVersion ?? null);
            this.task.savingStatus = "saving";
            if(this.lastRecord.id){
                axios.delete(process.env.VUE_APP_API_SERVER + "/audio-files/" + this.lastRecord.id);
                this.lastRecord = {url: null};
            }

            axios({
                method : "post",
                url    : process.env.VUE_APP_API_SERVER + "/audio-files",
                data   : formData,
                headers: {
                    "content-type": `multipart/form-data;`
                }
            }).then(response => {
                this.lastRecord = response.data;
                this.lastRecord.url = process.env.VUE_APP_API_SERVER + "/audio-files/" + this.lastRecord.id;
                this.task.savingStatus = "saved";
                /*   console.log('Downloadable audio', window.URL.createObjectURL(data));
                 console.log(response)*/
                this.fileRecorded = true;
                this.$nextTick(()=>{
                    this.task.savingStatus = null;
                })

            })
            //console.log('The blob data:', data);
        },

        triggerNext: function () {
            this.$emit('saveAudioPath', this.lastRecord.url);
            this.$emit('triggerNext', this.isTaskItem);
        },
        nl2br(text)
        {
            return text.replace(/\n/g, '<br />')
        }
    }
}
</script>

<style scoped>

</style>
