<template>
    <b-container class="mt-5">
        <b-overlay :show="this.showOverlay" rounded="sm">
            <component
                :is="this.currentComponentData.component"
                v-bind="this.currentComponentData.props"
                :key="this.currentIndex"
                @triggerNext="goToNext"
                @wrongAnswer="addWrongAnswer"
                @goodAnswer="addGoodAnswer"
                @answer="saveAnswer"
                @saveFormInformation="saveFormData"
                v-show="this.currentComponentData.component !== null"/>
            <b-progress :value="this.currentTaskIndex" :max="this.taskItems" variant="info"
                        v-show="this.currentTaskIndex !== 0"></b-progress>
            <template #overlay>
                <div class="text-center">
                    <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
                    <p id="cancel-label">{{ overlayMessage }}</p>
                </div>
            </template>
        </b-overlay>
    </b-container>
</template>

<script>
import ExternalHtmlContent from '@/components/TasksContent/ExternalHtmlContent';
import JsonForm from '@/components/TasksContent/JsonForm';
import LexicalDecision from '@/components/TasksContent/LexicalDecision';
import LexTaleResults from "@/components/TasksContent/LexTaleResults"
import SaveableTask from "@/components/SaveableTask"

export default {
    extends   : SaveableTask,
    name      : "LexTaleTask",
    components: {ExternalHtmlContent, JsonForm, LexicalDecision, LexTaleResults},
    props     : {
        displayResults: {type: Boolean, default: true},
        mustContinue  : {type: Boolean, default: false}
    },
    data()
    {
        return {
            wrongAnswer      : 0,
            goodAnswerWord   : 0,
            goodAnswerNonWord: 0,
            taskItems        : 0,
            currentTaskIndex : 0,
            taskStartedAt    : null,
            totalWords       : 0,
            totalNonWords    : 0,
            answers          : [],
        }
    },

    computed: {
        formattedScore: function () {
            return (Math.round(this.score * 10) / 10) + "%";
        },
        score         : function () {
            return ((this.goodAnswerWord / this.totalWords * 100) + (this.goodAnswerNonWord / this.totalNonWords * 100)) / 2
        },
    },
    methods : {
        parseTaskData             : function (data) {
            let uniqueID = 0;
            let order = 1;

            if (this.skipIntro === false && !this.participantData.id) {
                //Start with intro
                if (data.intro.source === 'html') {
                    this.timeline.push({
                        id       : uniqueID++,
                        component: "ExternalHtmlContent",
                        props    : {
                            fileName : data.intro.file,
                            nextLabel: data.common.nextLabel ?? null
                        }
                    });
                }
                //Continue with form
                if (data.form) {
                    this.timeline.push({
                        id       : uniqueID++,
                        component: data.form.type,
                        props    : Object.assign({}, data.common, data.form.props)
                    });
                }
            }

            //Continue with instructions
            if (data.instructions.source === 'html') {
                this.timeline.push({
                    id       : uniqueID++,
                    component: "ExternalHtmlContent",
                    props    : {
                        fileName : data.instructions.file,
                        nextLabel: data.common.nextLabel ?? null
                    }
                });
            }
            for (let i in data.timeline) {
                // Lexical decision items
                this.taskItems++;
                this.totalWords += data.timeline[i].answer === "WORD" ? 1 : 0;
                this.totalNonWords += data.timeline[i].answer === "NONWORD" ? 1 : 0;
                this.timeline.push({
                    id       : uniqueID++,
                    component: data.timeline[i].type,
                    props    : {
                        isTaskItem: true,
                        word      : data.timeline[i].word,
                        answer    : data.timeline[i].answer,
                        yesLabel  : data.common.yesLabel ?? '',
                        noLabel   : data.common.noLabel ?? '',
                        order     : order++
                    }
                });
            }
            this.timeline.push({
                id       : uniqueID++,
                component: LexTaleResults,
                props    : {
                    isResultScreen: true,
                    task          : this,
                    nextLabel     : data.common.nextLabel,
                }
            });
            console.log("This task includes a total of " + this.taskItems + ", " + this.totalNonWords + " non words and " + this.totalWords + " words")
            return true;
        },
        addGoodAnswer             : function (word) {
            this.goodAnswerWord += word === "WORD" ? 1 : 0;
            this.goodAnswerNonWord += word === "NONWORD" ? 1 : 0;
            //console.log("So far, " + this.goodAnswerWord + " good words, and " + this.goodAnswerNonWord + " good non-words after " + this.elapsedTime)
        },
        addWrongAnswer            : function () {
            this.wrongAnswer++;
        },
        saveAnswer                : function (order, word, wordType, correct) {
            this.answers.push({order: order, word: word, type: wordType, score: correct === true ? 1 : 0})
            //console.log(this.answers);
        },
        saveParticipantInformation: function (formData) {
            this.participantData = formData;
        },
        prepareResults            : function ()
        {
            this.exportableResults = {
                participantData: this.participantData ?? 'ANONYMOUS PARTICIPANT',
                score          : this.score,
                duration       : this.elapsedTime(),
                answers        : this.answers
            };
        }
    }
}
</script>
