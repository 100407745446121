<template>
    <div>
        <b-card no-body>
            <b-form @submit="onSubmit" novalidate>
                <b-card-body>
                    <b-card-title v-if="title !== ''">{{ title }}</b-card-title>
                    <p v-if="intro !== ''" v-html="nl2br(intro)"/>
                    <div v-for="field in questionList" :key="field.id">
                        <b-form-group class="mb-3"
                                      v-if="['radio-group', 'select', 'checkbox-group'].includes(field.type)"
                                      :id="field.id + '-group'"
                                      :label="field.label"
                                      :label-for="field.id"
                                      :description="field.description"
                                      :required="field.required === true"
                                      :state="field.validation">
                            <component :is="'b-form-' + field.type"
                                       v-model="form[field.id]"
                                       :options="field.options"
                                       :id="field.id" :name="field.id"/>
                        </b-form-group>
                    </div>
                </b-card-body>
                <b-card-footer>
                    <b-button type="submit" v-show="nextLabel !== null" variant="primary">{{ nextLabel }}</b-button>
                </b-card-footer>
            </b-form>
        </b-card>
    </div>
</template>

<script>
import BasicContent from "./BasicContent";

export default {
    extends: BasicContent,
    name   : "SurveyMultiChoice",
    props  : {
        title    : String,
        intro    : String,
        questions: Array,
        options  : Array,
        answers  : Array,
        nextLabel: String
    },
    data()
    {
        return {
            questionList: [],
            form        : {},
            goodAnswer  : 0,
            badAnswer   : 0,
        }
    },
    mounted()
    {
        let questionList = []
        let options = this.options;

        for (let i in this.questions) {
            if (this.task.debug ?? false) {
                options[i] = Object.values(options[i]).map((option) => {
                    if (option === this.answers[i]) {
                        return {value: option, text: '=' + option};
                    } else {
                        return {value: option, text: option};
                    }
                })
                //console.log(options[i])
            }
            questionList.push({
                id     : 'question_' + i,
                label  : this.questions[i],
                type   : this.type ? this.type[i] : "radio-group",
                options: options[i],
                answer : this.answers[i]
            })
        }
        //console.log(questionList);
        this.$set(this, "questionList", questionList);
    },
    methods: {

        onSubmit(event)
        {
            event.preventDefault();
            let results = [];
            let itemScore = 0;
            let totalScore = 0;
            for (let i in this.questionList) {
                itemScore = this.correctFieldAnswer(this.questionList[i]) ? 1 : 0;
                totalScore += itemScore
                results.push({
                    "id"           : this.questionList[i]['id'],
                    "answer"       : this.form[this.questionList[i]['id']],
                    "correctAnswer": this.questionList[i].answer,
                    "score"        : itemScore
                })
            }

            this.$emit("saveFormInformation", {
                type   : 'survey-multi-choice',
                content: {
                    answers: results,
                    score  : totalScore,
                    count  : this.questionList.length
                }
            });
            this.$emit("triggerNext", this.isTaskItem);
        },
        correctFieldAnswer(x)
        {
            return x.answer === this.form[x.id]
        }


    }
}
</script>

