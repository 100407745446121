<template>
            <b-container class="mt-5">
                <b-overlay :show="this.showOverlay" rounded="sm">
                <component
                    :is="this.currentComponentData.component"
                    v-bind="this.currentComponentData.props"
                    :key="this.currentIndex"
                    @triggerNext="goToNext()"
                    @saveAudioPath="saveAudioData"
                    @saveFormInformation="saveFormData"
                    :task="this"
                    v-show="this.currentComponentData.component !== null && this.isReady"/>
                    <template #overlay>
                        <div class="text-center">
                            <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
                            <p id="cancel-label">{{ overlayMessage }}</p>
                        </div>
                    </template>
                </b-overlay>
            </b-container>
</template>

<script>
import ExternalHtmlContent from '@/components/TasksContent/ExternalHtmlContent';
import ExternalHtmlConsentForm from '@/components/TasksContent/ExternalHtmlConsentForm';
import JsonForm from '@/components/TasksContent/JsonForm';
import ExternalVideoPlayer from '@/components/TasksContent/ExternalVideoPlayer';
import SimpleTextSlide from '@/components/TasksContent/SimpleTextSlide';
import AudioNarrativeRecorder from '@/components/TasksContent/AudioNarrativeRecorder';
import SaveableTask from "@/components/SaveableTask.js"


export default {
    extends   : SaveableTask,
    name      : "NarrativeTask",
    components: {
        ExternalHtmlContent,
        ExternalHtmlConsentForm,
        JsonForm,
        ExternalVideoPlayer,
        SimpleTextSlide,
        AudioNarrativeRecorder
    },

    data()
    {
        return {
            audioData    : {},
            narrativeData: {}
        }
    },
    methods: {
        parseTaskData: function (data) {
            let uniqueID = 0;
            if (this.skipIntro === false && !this.participantData.id) {
                //Start with intro
                if (data.intro) {
                    this.timeline.push({
                        id       : data.intro.id ?? uniqueID++,
                        component: data.intro.type,
                        props    : Object.assign(data.intro.props, data.common)
                    });
                }

                //Continue with form
                if (data.form) {
                    this.timeline.push({
                        id       : data.form.id ?? uniqueID++,
                        component: data.form.type,
                        props    : Object.assign(data.form.props, data.common)
                    });
                }
            }

            //Continue with instructions
            if (data.instructions) {
                this.timeline.push({
                    id       : data.instructions.id ?? uniqueID++,
                    component: data.instructions.type,
                    props    : Object.assign({}, data.common, data.instructions.props)
                });
            }

            // Continue with the task itself
            for (let i in data.timeline) {
                // task items
                this.taskItems++;
                this.timeline.push({
                    id       : data.timeline[i].id ?? data.timeline[i].type + '-' + uniqueID++,
                    component: data.timeline[i].type,
                    props    : Object.assign({}, data.common, data.timeline[i].props)
                });
            }
            return true;
        },
        saveAudioData(data)
        {
            let currentItem = this.timeline[this.currentIndex];
            this.audioData[this.currentIndex + '-' + currentItem.id] = data;
            console.log(this.audioData);
        },
        prepareResults: function ()
        {
            this.exportableResults = {
                duration       : this.elapsedTime(),
                participantData: this.participantData,
                audioData      : this.audioData,
                narrativeData  : this.timelineData
            };
        }

    }
}
</script>

<style scoped>

</style>
