<template>
    <b-container class="mt-5">
        <b-overlay :show="this.showOverlay" rounded="sm">
            <component
                :is="this.currentComponentData.component"
                v-bind="this.currentComponentData.props"
                :key="this.currentIndex"
                @triggerNext="goToNext()"
                @saveAudioPath="saveAudioData"
                @saveFormInformation="saveFormData"
                :task="this"
                v-show="this.currentComponentData.component !== null && this.isReady"/>
            <template #overlay>
                <div class="text-center">
                    <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
                    <p id="cancel-label">{{ overlayMessage }}</p>
                </div>
            </template>
        </b-overlay>
    </b-container>
</template>

<script>
import ExternalHtmlContent from '@/components/TasksContent/ExternalHtmlContent';
import ExternalHtmlConsentForm from '@/components/TasksContent/ExternalHtmlConsentForm';
import JsonForm from '@/components/TasksContent/JsonForm';
import SurveyMultiChoice from '@/components/TasksContent/SurveyMultiChoice';
import ClozeText from '@/components/TasksContent/ClozeText';
import TaskResults from '@/components/TasksContent/TaskResults';
import SimpleTextSlide from '@/components/TasksContent/SimpleTextSlide';
import SaveableTask from "@/components/SaveableTask.js"

export default {
    extends   : SaveableTask,
    name      : "PlacementTask",
    components: {
        ExternalHtmlContent,
        ExternalHtmlConsentForm,
        JsonForm,
        SurveyMultiChoice,
        ClozeText,
        TaskResults,
        SimpleTextSlide,
    },
    props     : {
        displayResults: {type: Boolean, default: true},
        mustContinue  : {type: Boolean, default: false}
    },
    data()
    {
        return {
            audioData           : {},
            showResultsInPercent: false,
        }
    },
    computed: {
        totalScore()
        {
            return Object.values(this.timelineData).filter((current) => current.score ?? false).reduce((prev,
                                                                                                        next) => prev + next.score, 0);
        },
        totalCount()
        {
            return Object.values(this.timelineData).filter((current) => current.count ?? false).reduce((prev,
                                                                                                        next) => prev + next.count, 0);
        },
        formattedScore()
        {
            return this.showResultsInPercent ?
                (this.totalScore / this.totalCount).round(2) + "%" :
                this.totalScore + ' / ' + this.totalCount;
        }
    },
    methods : {
        parseTaskData: function (data) {
            let uniqueID = 0;
            this.showResultsInPercent = data.showResultsInPercent ?? false;
            if (this.skipIntro === false && !this.participantData.id) {
                //Start with intro
                if (data.intro) {
                    this.timeline.push({
                        id       : data.intro.id ?? uniqueID++,
                        component: data.intro.type,
                        props    : Object.assign(data.intro.props, data.common)
                    });
                }

                //Continue with form
                if (data.form) {
                    this.timeline.push({
                        id       : data.form.id ?? uniqueID++,
                        component: data.form.type,
                        props    : Object.assign(data.form.props, data.common)
                    });
                }
            }

            //Continue with instructions
            if (data.instructions) {
                this.timeline.push({
                    id       : data.instructions.id ?? uniqueID++,
                    component: data.instructions.type,
                    props    : Object.assign(data.instructions.props, data.common)
                });
            }

            // Continue with the task itself
            for (let i in data.timeline) {
                // task items
                this.taskItems++;
                this.timeline.push({
                    id       : data.timeline[i].id ?? data.timeline[i].type + '-' + uniqueID++,
                    component: data.timeline[i].type,
                    props    : Object.assign({}, data.common, data.timeline[i].props)
                });
            }
            return true;
        },
        saveAudioData(data)
        {
            // Unused at the moment
            let currentItem = this.timeline[this.currentIndex];
            this.audioData[this.currentIndex + '-' + currentItem.id] = data;
        },
        prepareResults: function ()
        {
            let level = null;
            if (this.taskJson.levels) {
                level = this.taskJson.levels.find(el => {
                    return el.min <= this.totalScore && el.max >= this.totalScore;
                }).level;
            }
            console.log(level)
            this.exportableResults = {
                duration       : this.elapsedTime(),
                score          : this.totalScore,
                level          : level ?? null,
                participantData: this.participantData,
                timelineData   : this.timelineData
            };
            console.log(this.exportableResults);
        }

    }
}
</script>

<style scoped>

</style>
