<template>
    <b-card no-body>
        <b-card-body ref="htmlContent">
            <b-skeleton animation="wave" width="85%"></b-skeleton>
            <b-skeleton animation="wave" width="55%"></b-skeleton>
            <b-skeleton animation="wave" width="70%"></b-skeleton>
        </b-card-body>
        <b-card-footer>
            <b-button @click="triggerNext()" v-show="nextLabel !== null" variant="primary">{{ nextLabel }}</b-button>
        </b-card-footer>
    </b-card>
</template>

<script>
import axios from 'axios'

export default {
    name : "ExternalHtmlConsentForm",
    props: {
        fileName : String,
        nextLabel: String
    },
    mounted()
    {
        axios.get('/html/' + this.fileName).then(response => this.$refs["htmlContent"].innerHTML = response.data)
    },
    methods: {
        triggerNext: function () {
            this.$emit("saveFormInformation", {type: 'consent', content: [{consent: true}]});
            this.$emit("triggerNext");
        }
    }
};
</script>
