<template>
    <div>
        <b-card no-body>
            <b-card-body class="justify-content-center d-flex">
            <video-player :options="playerOptions"
                          @ended="onPlayerEnded($event)"/>
            </b-card-body>
        </b-card>
    </div>
</template>

<script>

export default {

    name : "ExternalVideoPlayer",
    props: {
        source  : String,
        autoplay: String,
        isTaskItem: Boolean

    },
    data()
    {
        return {
            playerOptions: {
                height    : 480,
                responsive: true,
                // videojs options
                autoplay: this.autoplay,
                muted   : false,
                language: 'es',
                sources : [
                    {
                        type: "video/mp4",
                        src : this.source
                    }
                ],
                poster  : "",
            }
        }
    },
    mounted()
    {
        console.log(this.source)
    },
    methods: {
        onPlayerEnded: function () {
            console.log("video ended");
            this.$emit("triggerNext", this.isTaskItem);
        }
    }
}
</script>

<style scoped>

</style>
