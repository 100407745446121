<template>
    <div>
        <b-container class="mt-3">
            <h1 class="mb-5">Études en cours</h1>
            <b-row cols="2">
                <b-col v-for="study in studies" :key="study.id" sm="6" class="mb-3">
                    <b-card>
                        <b-card-title>
                            {{ study.name }}
                            <b-icon :icon="study.icon" v-if="study.icon !== null"/>
                        </b-card-title>
                        <b-card-text>
                            {{ study.description }}
                        </b-card-text>
                        <b-button :to="study.link" :variant="study.link === '#' ? 'secondary' : 'primary'">
                            {{ study.buttonText ? study.buttonText : 'Participer maintenant!' }}
                        </b-button>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
export default {
    name: "StudyList",
    data()
    {
        return {
            studies: [
                {
                    id         : "tam-cruz-enriquez-2021-es-l2",
                    name       : "TAM VIDEO (ES-L2)",
                    description: "Un estudio sobre el uso de los tiempos verbales para hablantes del español como lengua segunda.",
                    link       : "/studies/tam-cruz-enriquez-2021-es-l2",
                    buttonText : "¡Participa ahora!",
                    icon       : ""
                },
                {
                    id         : "tam-cruz-enriquez-2021-es-l1",
                    name       : "TAM VIDEO (ES-L1)",
                    description: "Un estudio sobre el uso de los tiempos verbales para nativos del español.",
                    link       : "/studies/tam-cruz-enriquez-2021-es-l1",
                    buttonText : "¡Participa ahora!",
                    icon       : ""
                },{
                    id         : "tam-cruz-enriquez-2021-en-l2",
                    name       : "TAM VIDEO (EN-L2)",
                    description: "A study on the use of verb tenses in English L2.",
                    link       : "/studies/tam-cruz-enriquez-2021-en-l2",
                    buttonText : "Participate now!",
                    icon       : ""
                },
                {
                    id         : "tam-cruz-enriquez-2021-en-l1",
                    name       : "TAM VIDEO (EN-L1)",
                    description: "A study on the use of verb tenses in English L1.",
                    link       : "/studies/tam-cruz-enriquez-2021-en-l1",
                    buttonText : "Participate now!",
                    icon       : ""
                }, {
                    id         : "tam-cruz-enriquez-2021-fr-l2",
                    name       : "TAM VIDEO (FR-L2)",
                    description: "Une étude sur l'utilisation des temps verbaux en français langue seconde.",
                    link       : "/studies/tam-cruz-enriquez-2021-fr-l2",
                    buttonText : "Participe dès maintenant!",
                    icon       : ""
                },
                {
                    id         : "tam-cruz-enriquez-2021-fr-l1",
                    name       : "TAM VIDEO (FR-L1)",
                    description: "Une étude sur l'utilisation des temps verbaux en français langue maternelle.",
                    link       : "/studies/tam-cruz-enriquez-2021-fr-l1",
                    buttonText : "Participe dès maintenant!",
                    icon       : ""
                }
            ]
        }
    }

}
</script>

<style scoped>

</style>
