<template>
    <div>
        <b-card no-body>
            <b-form @submit="onSubmit" @reset="onReset" novalidate>
                <b-card-body>
                    <b-card-title v-if="title !== ''">{{ title }}</b-card-title>
                    <p v-if="intro !== ''" v-html="nl2br(intro)"/>
                    <div v-for="field in completedStructure" :key="field.id">
                        <b-form-group v-if="['text', 'email', 'date', 'number'].includes(field.type)"
                                      :id="field.id + '-group'"
                                      :label="field.label"
                                      :label-for="field.id"
                                      :state="field.validation"
                                      :required="field.required === true"
                                      :description="field.description">
                            <b-form-input
                                v-model="form[field.id]"
                                :id="field.id"
                                :type="field.type"
                                :state="field.validation"
                                :placeholder="field.placeholder"
                                @blur="validateField(field)"
                                @change="validateField(field)"
                            />
                            <b-form-invalid-feedback :state="field.validation">
                                {{ invalidFeedBack }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group v-if="['radio-group', 'select', 'checkbox-group'].includes(field.type)"
                                      :id="field.id + '-group'"
                                      :label="field.label"
                                      :label-for="field.id"
                                      :description="field.description"
                                      :required="field.required === true"
                                      :state="field.validation">
                            <component :is="'b-form-' + field.type"
                                       v-model="form[field.id]"
                                       :options="field.options"
                                       :id="field.id" :name="field.id"
                                       @change="validateField(field)"/>
                            <b-form-invalid-feedback :state="field.validation">
                                {{ invalidFeedBack }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group v-else-if="field.type === 'textarea'"
                                      :id="field.id + '-group'"
                                      :label="field.label"
                                      :label-for="field.id"
                                      :required="field.required === true"
                                      :description="field.description">
                            <b-form-textarea
                                v-model="form[field.id]"
                                @focus="validateField(field, true)"
                                @blur="validateField(field)"
                                :state="field.validation"
                                :required="field.required === true"
                                :id="field.id"
                                :placeholder="field.placeholder"
                                :rows="field.rows"
                            />
                            <b-form-invalid-feedback :state="field.validation">
                                {{ minWordWarning(field.min) }}
                            </b-form-invalid-feedback>
                            <small v-if="field.wordCountEnabled" :id="field.id + '-group__BV_word_count_'"
                                   class="form-text text-muted">
                                {{ computeWordCount(field) }}
                            </small>
                        </b-form-group>
                        <div v-else-if="field.type === 'instructions'">
                            <p v-html="nl2br(field.description)"></p>
                        </div>
                    </div>
                </b-card-body>
                <b-card-footer>
                    <b-button type="submit" v-show="nextLabel !== null" variant="primary">{{ nextLabel }}</b-button>
                    <b-button class="ml-2" type="button" v-show="skipLabel !== '' && skipTo !== ''" variant="warning"
                              @click="onSkipToSection">{{ skipLabel }}
                    </b-button>
                </b-card-footer>
            </b-form>
        </b-card>
    </div>
</template>

<script>
import BasicContent from "./BasicContent";

export default {
    extends: BasicContent,
    name   : "JsonForm",
    props  : {
        title          : {type: String, default: ''},
        intro          : {type: String, default: ''},
        structure      : [],
        feedback       : {
            type: Object, default()
            {
                return {
                    "isFieldMandatory": "This field is required",
                    "word"            : {"singular": "word", "plural": "words"},
                    "minWordRequired" : "A minimum of :word words are required."
                }
            }
        },
        nextLabel      : String,
        skipLabel      : {type: String, default: ''},
        skipTo         : {type: String, default: ''},
        formType       : String,
        isTaskItem     : Boolean,
        afterValidation: String
    }
    ,
    data()
    {
        return {
            form              : {},
            completedStructure: []
        }
    }
    ,
    mounted()
    {
        let count = 0;
        this.completedStructure = this.structure.map((x) => {
            let id = x.id ?? 'field' + (count++);
            let defaultOptions = {
                label      : x.label ?? '',
                required   : x.required ?? false,
                type       : x.type ?? 'text',
                placeholder: x.placeholder ?? '',
                id         : id,
                description: x.description ?? '',
                value      : x.value ? x.value : (x.type === 'checkbox-group' ? [] : ''),
            }
            return Object.assign({}, defaultOptions, x);
        })
        //Fill in the form
        this.completedStructure.forEach((x) => {
            this.$set(this.form, x.id, x.value);
        })
    }
    ,
    computed: {
        invalidFeedBack()
        {
            return this.feedback.isFieldMandatory;
        }
    }
    ,
    methods: {
        onSkipToSection()
        {
            this.$emit("skipToSection", this.skipTo);
        }
        ,
        onSubmit(event)
        {
            event.preventDefault();

            this.completedStructure.forEach((x) => {
                this.validateField(x);
            })

            let errorFields = this.completedStructure.filter((x) => x.validation === false);

            if (errorFields.length) {
                return false;
            }

            this.$emit("saveFormInformation", {type: this.formType, content: this.form});
            this.$emit("triggerNext", this.isTaskItem);
        }
        ,
        validateField(x, removeError)
        {
            if (x.wordCountEnabled && (x.wordCount ?? 0) < x.min) {
                this.$set(x, "validation", removeError ? null : false);
            } else if (x.required === true && (!this.form[x.id] || this.form[x.id] === '')) {
                this.$set(x, "validation", removeError ? null : false);
            } else if (x.type.required && x.type === 'email' && (this.form[x.id]).match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/g) === null) {
                this.$set(x, "validation", removeError ? null : false);
            } else {
                this.$set(x, "validation", x.required === true ? true : null);
            }
        }
        ,
        onReset(event)
        {
            event.preventDefault()
            // Reset our form values
            for (let i in this.form) {
                this.form[i] = '';
            }
        }
        ,
        minWordWarning(min)
        {
            let minWordRequired = this.feedback.minWordRequired ?? '';
            return minWordRequired.replace(":word", min);
        }
        ,
        computeWordCount(field)
        {
            let text = this.form[field.id] ?? '';
            if (text === '') {
                return '';
            }
            field.wordCount = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().split(/\W+/).length;
            return field.wordCount + ' ' + (field.wordCount == 1 ? this.feedback.word.singular : this.feedback.word.plural);
        }

    }
}
</script>
