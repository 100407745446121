import SaveableTask from "@/components/SaveableTask.js"

export default {
    name   : "BasicContent",
    props  : { 
        task: SaveableTask
    },
    methods: {
        nl2br(text)
        {
            return text.replace(/\n/g, '<br />').replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;')
        }
    }
}
