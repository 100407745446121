<template>
    <b-card class="text-center">
        <b-card-title>
            {{ word }}
        </b-card-title>

        <b-button @click="answerNo()" variant="danger">{{noLabel}}</b-button>
        <b-button @click="answerYes()" class="ml-3" variant="success">{{yesLabel}}</b-button>

    </b-card>
</template>

<script>
export default {
    name: "LexicalDecision",
    props:{
        word:String,
        answer:String,
        yesLabel:String,
        noLabel:String,
        order:Number
    },
    methods: {
        answerYes: function(){
            if(this.answer === "WORD"){
                this.$emit("goodAnswer", "WORD")
            }else{
                this.$emit("wrongAnswer")
            }
            this.$emit("answer", this.order, this.word, this.answer, this.answer === "WORD")
            this.$emit("triggerNext", true)
        },
        answerNo: function(){
            if(this.answer === "NONWORD"){
                this.$emit("goodAnswer", "NONWORD")
            }else{
                this.$emit("wrongAnswer")
            }
            this.$emit("answer", this.order, this.word, this.answer, this.answer === "NONWORD")
            this.$emit("triggerNext", true)
        }
    }
}
</script>
