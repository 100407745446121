<template>
    <b-container class="about mt-3">
        <b-card title="À propos de ce portail">



        <p>
            L'objectif de ce portail est de proposer en accès gratuit et ouvert différentes tâches développées sur
            mesure ou provenant d'études antérieures. Ces tâches peuvent être utilisées dans le cadre de futures études.

        </p>
        <p>
            Si vous désirez utiliser une ou plusieurs de ces tâches pour vos études,
            veuillez contacter l'<a href="mailto:huglacroix+lt@gmail.com">auteur</a>
            pour qu'il vous configure un accès aux résultats des tests de vos
            participants.
        </p>
        <p>
            Si vous désirez des tâches sur mesures ou encore une combinaison de
            plusieurs tâches existantes, l'<a href="mailto:huglacroix+lt@gmail.com"
        >auteur</a
        >
            du site peut également s'en occuper contre une rémunération. N’hésitez pas à lui écrire pour obtenir un devis.
        </p>
        </b-card>
    </b-container>
</template>
<script>
export default {
    name      : "About",
    components: {},
};
</script>
