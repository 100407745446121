<template>
    <div>
        <b-card no-body>
            <b-form @submit="onSubmit" novalidate>
                <b-card-body>
                    <b-card-title v-if="title !== ''">{{ title }}</b-card-title>
                    <p v-if="intro !== ''" v-html="nl2br(intro)"/>
                    <hr/>
                    <div ref="content" class="form-inline">
                    </div>
                </b-card-body>
                <b-card-footer>
                    <b-button type="submit" v-show="nextLabel !== null" variant="primary">{{ nextLabel }}</b-button>
                </b-card-footer>
            </b-form>
        </b-card>
    </div>
</template>

<script>
import BasicContent from "./BasicContent";
import {BFormSelect} from 'bootstrap-vue/src/components/form-select';
import Vue from "vue";
export default {
    extends   : BasicContent,
    components: {},
    name      : "ClozeText",
    props     : {
        title    : String,
        intro    : String,
        questions: Array,
        options  : Array,
        answers  : Array,
        nextLabel: String
    },
    data()
    {
        return {
            questionList    : [],
            form            : {},
            questionRegex   : /\$\{\s*(.*?)\s*\}/g,
            replacementCount: 0
        }
    },
    mounted()
    {
        let questionHtml = '';
        for (let i in this.questions) {
            questionHtml += this.parseItem(i);
        }
        this.$refs["content"].innerHTML = questionHtml;
        for (let i in this.questionList) {
            this.questionList[i].component.$mount();
            document.getElementById("mounting_" + this.questionList[i].id).appendChild(this.questionList[i].component.$el);
        }
    },
    methods: {
        parseItem(i)
        {
            let content = this.questions[i].replace(this.questionRegex, this.itemReplacer);
            return content;
        },
        itemReplacer(match, p1)
        {
           let injectedString = '<span id="mounting_cloze_' + this.replacementCount + '"></span>'
            let hasCorrect = false;
            let currentItem = {id: "cloze_" + this.replacementCount};
            let options = [];
            p1.split('~').forEach((option) => {
                let content = option;
                let label = content;
                if (content.startsWith("=")) {
                    content = content.substr(1);
                    currentItem.answer = content;
                    hasCorrect = true;
                    if(this.task.debug ?? false){
                        label = "=" + content;
                    }else {
                        label = content;
                    }
                }
                options.push({value: content, text: label});
            });

            if (!hasCorrect) {
                console.log("WARNING: no correct answer defined on " + p1);
                currentItem.answer = null;
            }

            currentItem.component = new (Vue.extend(BFormSelect))({
                props:{
                    parentComponent: Object,
                },
                propsData: {
                    parentComponent: this,
                    id: 'cloze_' + this.replacementCount,
                    options: options,
                },
                created(){
                    this.$on(['change'], e => {
                        this.parentComponent.form[this.id] = e;
                    })
                }
            });
            this.replacementCount++;
            this.questionList.push(currentItem);
            return injectedString;
        },
        onSubmit(event)
        {
            event.preventDefault();
            let results = [];
            let itemScore = 0;
            let totalScore = 0;
            for (let i in this.questionList) {
                itemScore = this.correctFieldAnswer(this.questionList[i]) ? 1 : 0;
                totalScore += itemScore
                results.push({
                    "id"           : this.questionList[i].id,
                    "answer"       : this.form[this.questionList[i].id],
                    "correctAnswer": this.questionList[i].answer,
                    "score"        : itemScore
                })
            }
            this.$emit("saveFormInformation", {
                type   : 'cloze-text',
                content: {
                    answers: results,
                    score  : totalScore,
                    count  : this.questionList.length
                }
            });
            this.$emit("triggerNext", this.isTaskItem);
        },
        correctFieldAnswer(x)
        {
            return x.answer === this.form[x.id]
        }


    }
}
</script>

