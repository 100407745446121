<template>
    <b-container class="mt-5">
        <component
            v-if="isWaiting === false"
            v-bind:is="taskType"
            :task-type="taskType"
            :task-version="taskVersion"
            :task-json="taskJson"/>

        <b-card no-body
                v-show="isWaiting === true || hasError === true">
            <b-card-body ref="htmlContent">
                <b-skeleton animation="wave" width="85%"></b-skeleton>
                <b-skeleton animation="wave" width="55%"></b-skeleton>
                <b-skeleton animation="wave" width="70%"></b-skeleton>
            </b-card-body>
        </b-card>

    </b-container>
</template>

<script>

import GenericTimelineTask from "@/components/GenericTimelineTask";
import LexTaleTask from "@/components/LexTaleTask";
import NarrativeTask from "@/components/NarrativeTask";
import PlacementTask from "@/components/PlacementTask";
import axios from "axios";

export default {
    name      : "TaskLoader",
    components: {
        GenericTimelineTask,
        LexTaleTask,
        NarrativeTask,
        PlacementTask,
    },
    props     : {},
    data()
    {
        return {
            taskType   : '',
            taskVersion: '',
            taskJson   : null,
            error : null
        }
    },
    beforeMount()
    {
        this.taskType = this.$route.params.taskType;
        this.taskVersion = this.$route.params.taskVersion;
        this.loadTaskData();
    },
    computed: {
        isWaiting: function () {
            return this.taskJson === null;
        },
        hasError: function(){
            return this.taskJson === null && this.error !== null;
        }
    },
    methods : {
        loadTaskData: function () {
            axios.get('/json/tasks/' + this.taskType + "-" + this.taskVersion + ".json")
                .then(response => this.taskJson = response.data)
                .catch(error => this.showError(error));
        },
        showError : function(error){
            this.error = error;
            this.$refs["htmlContent"].innerHTML = "Oops, il semblerait que cette tâche ne peut être chargée pour le moment!";
        }

    }
}
</script>

<style scoped></style>
